<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-full pr-2">
        <input
          v-model="personal_data.firstname"
          type="text"
          name="firstname"
          :placeholder="getFieldPlaceholder('firstname')"
        />
      </div>
      <div class="w-full pl-2">
        <input
          v-model="personal_data.lastname"
          type="text"
          name="lastname"
          :placeholder="getFieldPlaceholder('lastname')"
        />
      </div>
    </div>
    <input
      v-model="personal_data.email"
      readonly="true"
      type="mail"
      class="email"
      :placeholder="getFieldPlaceholder('email')"
    />
    <input
      v-model.lazy="personal_data.telephone"
      v-maska
      data-maska="+## ### ############"
      type="text"
      name="tel"
      :maxlength="20"
      :placeholder="getFieldPlaceholder('telephone')"
      :class="{ error: error_fields.telephone }"
    />
    <div v-if="isMobile">
      <input
        v-model="personal_data.birthday"
        name="birthday"
        class="birthday-input"
        :type="birthdayType"
        :readonly="!dateType"
        :placeholder="getFieldPlaceholder('birthday')"
        @focus.once="birthdayFocus"
      />
    </div>
    <client-only v-else>
      <VendorVueDatePick
        v-model="personal_data.birthday"
        name="birthday"
        class="reward-birthday-picker"
        :input-attributes="{
          readonly: true,
          placeholder: getFieldPlaceholder('birthday'),
        }"
        :display-format="'DD.MM.YYYY'"
        :is-date-disabled="isFutureDate"
        :weekdays="weekdays"
        :months="months"
        :selectable-year-range="{ from: 1930, to: new Date().getFullYear() }"
      />
    </client-only>
    <div class="flex flex-row space-between">
      <div class="w-3/4 pr-1">
        <input
          v-model="personal_data.street"
          type="text"
          name="street_1"
          class="w-full h-10 p-2 my-2 text-base text-white"
          :placeholder="getFieldPlaceholder('street')"
          :class="{ error: error_fields.street }"
        />
      </div>
      <div class="w-1/4 pl-1">
        <input
          v-model="personal_data.street_nr"
          type="text"
          name="housenumber"
          :maxlength="8"
          :placeholder="getFieldPlaceholder('street_nr')"
          :class="{ error: error_fields.street_nr }"
        />
      </div>
    </div>
    <div class="flex flex-row">
      <div class="w-1/3 pr-2">
        <input
          v-model="personal_data.postcode"
          v-maska
          data-maska="#####"
          type="text"
          name="postal-code"
          :placeholder="getFieldPlaceholder('postcode')"
        />
      </div>
      <div class="w-2/3 pl-2">
        <input
          v-model="personal_data.city"
          type="text"
          name="city"
          :placeholder="getFieldPlaceholder('city')"
          :class="{ error: error_fields.city }"
        />
      </div>
    </div>
    <select
      v-model="personal_data.country"
      class="w-full mt-2"
      name="country"
      :placeholder="getFieldPlaceholder('country')"
    >
      <option
        v-for="country in countries"
        :key="country.code"
        :value="country.code"
      >
        {{ country.text }}
      </option>
    </select>
    <slot name="after-form" />
    <p v-t="{ path: 'components.rewards.required_info' }" />
  </div>
</template>

<script>
import { mapStores } from 'pinia';

export default {
  props: {
    deliveryType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      personal_data: {
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
        street: '',
        street_nr: '',
        city: '',
        postcode: '',
        country: 'AT',
        birthday: '',
      },
      countries: [
        { code: 'AT', text: 'Österreich' },
        { code: 'DE', text: 'Deutschland' },
        { code: 'CH', text: 'Schweiz' },
      ],
      required_fields: {
        digital: ['firstname', 'lastname', 'email', 'telephone', 'birthday'],
        delivery: [
          'firstname',
          'lastname',
          'email',
          'street',
          'street_nr',
          'city',
          'postcode',
          'country',
          'telephone',
          'birthday',
        ],
      },
      error_fields: {
        firstname: false,
        lastname: false,
        email: false,
        telephone: false,
        street: false,
        street_nr: false,
        city: false,
        postcode: false,
        country: false,
      },
      phone_regex: /^([^A-Z]{0,20})$/,
      string_regex: /^([^0-9]*)$/,
      dateType: false,
    };
  },
  computed: {
    ...mapStores(useMeStore, useMainStore),
    formFilled() {
      return Object.values(this.filterPersonalData(this.personal_data)).every(
        this.fieldNotEmpty
      );
    },
    formValid() {
      return Object.values(this.error_fields).every((v) => !v);
    },
    requiredFields() {
      return this.deliveryType === 'delivery'
        ? this.required_fields.delivery
        : this.required_fields.digital;
    },
    personalDataFetched() {
      return this.meStore.personal_data_fetched;
    },
    storedPersonalData() {
      return this.personalDataFetched
        ? this.meStore.personalDataTranslated
        : this.personal_data;
    },
    isMobile() {
      return (
        this.mainStore.platform === 'android' ||
        this.mainStore.platform === 'ios' ||
        this.mainStore.platform === 'mobileweb'
      );
    },
    weekdays() {
      return this.$tm(`components.datepicker.weekdays`);
    },
    months() {
      return this.$tm(`components.datepicker.months`);
    },
    birthdayType() {
      if (this.dateType) {
        return 'date';
      }

      const birthday = this.personal_data.birthday;
      return birthday === '' || birthday === null ? 'text' : 'date';
    },
  },
  watch: {
    personal_data() {
      this.formUpdated();
    },
    formFilled() {
      this.formUpdated();
    },
    formValid() {
      this.formUpdated();
    },
    'personal_data.telephone': function (newVal) {
      //eslint-disable-line
      this.error_fields.telephone = !this.phone_regex.test(newVal);
    },
    'personal_data.street': function (newVal) {
      //eslint-disable-line
      this.error_fields.street = !this.string_regex.test(newVal);
    },
    'personal_data.street_nr': function (newVal) {
      //eslint-disable-line
      if (newVal) {
        this.error_fields.street_nr = newVal.length > 8;
      }
    },
    'personal_data.city': function (newVal) {
      //eslint-disable-line
      this.error_fields.city = !this.string_regex.test(newVal);
    },
    storedPersonalData(val) {
      if (val) {
        this.personal_data = this.filterPersonalData(
          this.storedPersonalData,
          true
        );
        this.personal_data.telephone = this.storedPersonalData.telephone;
      }
    },
  },
  mounted() {
    if (this.personalDataFetched) {
      this.personal_data = this.filterPersonalData(
        this.storedPersonalData,
        true
      );
      this.personal_data.telephone = this.storedPersonalData.telephone;
    }
  },
  methods: {
    getFieldPlaceholder(name) {
      const required = this.requiredFields.includes(name) ? '*' : '';
      return this.$t(`components.rewards.fields.${name}`) + required;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    fieldNotEmpty(val) {
      if (val === '' || val == null) {
        return false;
      }
      return true;
    },
    filterPersonalData(data, ignoreDeliveryType = false) {
      return Object.keys(data)
        .filter((key) =>
          (ignoreDeliveryType
            ? this.required_fields.delivery
            : this.requiredFields
          ).includes(key)
        )
        .reduce((obj, key) => {
          obj[key] = data[key]; // eslint-disable-line
          return obj;
        }, {});
    },
    formUpdated() {
      this.$emit('updated', {
        formData: this.personal_data,
        filled: this.formFilled,
        valid: this.formValid,
      });
    },
    birthdayFocus() {
      // so code is only executed once
      if (this.dateType) {
        return;
      }

      this.dateType = true;

      this.$nextTick(() => {
        const element = document.querySelector('.birthday-input');
        element.blur();
        element.focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  box-sizing: border-box;
  @apply bg-white h-10 text-theme-primary border-theme-primary border border-solid;
  font-size: 13pt;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

option {
  @apply bg-white;
  border-top:1px solid #444;
  padding:.3em 1em .3em 1em;
}

option:checked {
  @apply bg-theme-primary-light text-white;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=string],
input[type=text],
input[type=mail],
input[type=date] {
  @apply bg-white p-2 my-2 w-full text-theme-primary h-10 text-base border-theme-primary border border-solid;
  box-sizing: border-box;
}

.birthday-input {
  @apply bg-white p-2 my-2 w-full text-theme-primary h-10 text-base border-theme-primary border border-solid;
  box-sizing: border-box;
}

.email {
  @apply border-gray-500 text-gray-500;
}

.error {
  border: #e3342f solid 2px !important;
}
</style>

<style lang="scss">
.vdpComponent {
  @apply bg-white p-2 my-2 w-full text-theme-primary h-10 border-theme-primary border border-solid;
  box-sizing: border-box;
}

.vdpComponent input {
  @apply w-full h-full text-lg;
}

.reward-birthday-picker .vdpOuterWrap.vdpPositionFixed {
  position: absolute !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
  padding: 0.5em 0 !important;
  background-color: transparent !important;
  margin-right: -1px;
}

.vdpCell.selected .vdpCellContent {
  @apply bg-theme-primary-light
}
</style>
